body {
  background-color: #03030F !important;
}

a {
  color: #fffff0 !important;
}

p {
  color: #fffff0 !important;
}
